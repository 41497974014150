import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { country } from '@features/country/data';
import { mediaUrl } from '@features/shared/data';
import { DUKAN_PROVIDER_NAME, storesCourses, storesQuestions } from '@features/stores/data';
import { GetDukanShopUseCase } from 'app/core/usecases/dukan-shop/get-dukan.usecase';
import { GetStoresUseCase } from 'app/core/usecases/merchant-store/get-stores-usecase';
import { BasePresenter } from 'app/presentation/base/base.presenter';
import { DukanShopModel } from '../../../core/domain/dukan-shop/dukan-shop.model';
import { GetUserStoresUseCase } from '../../../core/usecases/merchant-store/get-user-stores-usecase';
import { StoresSideEffect } from './stores.side-effects';
import { StoresViewEvents } from './stores.view-events';
import { StoresViewState } from './stores.view-state';

@Injectable()
export class StoresPresenter extends BasePresenter<
  StoresViewState,
  StoresViewEvents,
  StoresSideEffect
> {
  public dukanShop: DukanShopModel;

  public questions = storesQuestions;

  public courses = storesCourses;

  constructor(
    private _getStoresUseCase: GetStoresUseCase,
    private _getShopUseCase: GetDukanShopUseCase,
    private _getUserStoresUseCase: GetUserStoresUseCase,
    private _sanitizer: DomSanitizer,
  ) {
    super();
  }

  protected defaultViewState(): StoresViewState {
    return {
      revampedStoresEnabled: false,
      userStores: [],
      isUserEligibleToDukan: false,
      selectedStore: { name: '', icon: '', provider: '', redirectUri: '' },
      currentStoreCourse: {
        title: '',
        course_content: [],
      },
      isLoading: true,
      shouldDisplayEmptyState: false,
    };
  }

  protected onViewEvent(event: StoresViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.getStores();
        this.getDukanStore();
        this.getUserStores();
        break;
      }
      case 'SelectionChanged': {
        this.merge({
          selectedStore: event.store,
          currentStoreCourse: this.getSelectedStoreCourses(event.store),
        });

        break;
      }
      case 'StartStoreIntegration': {
        break;
      }
    }
  }

  getUserStores(): void {
    this._getUserStoresUseCase.execute().subscribe({
      next: (stores) => {
        const selectedCountry = country.code;
        const currentCountryLinkedStore = stores.find((store) => store.market === selectedCountry)!;

        if (currentCountryLinkedStore) {
          const currentStore = this.viewState.userStores.find(
            (store) => store.provider === currentCountryLinkedStore.provider,
          );

          this.merge({
            currentCountryLinkedStore,
            currentStoreCourse: this.getSelectedStoreCourses(currentStore),
          });
        }

        this.emitSideEffect({
          state: 'CurrentStoreSelected',
          payload: this.viewState,
        });
      },
    });
  }

  getStores(): void {
    this._getStoresUseCase.execute().subscribe({
      next: (stores) => {
        const userStoresList = [...this.viewState.userStores, ...stores];

        this.merge({
          userStores: userStoresList,
          selectedStore: userStoresList[0],
          currentStoreCourse: this.getSelectedStoreCourses(userStoresList[0]),
        });
      },
    });
  }

  getSelectedStoreCourses(store = this.viewState.selectedStore): {
    title: string;
    course_content: { course_title: string; course_link: string }[];
  } {
    return (
      this.courses[store.provider as keyof typeof this.courses] || {
        title: '',
        course_content: [],
      }
    );
  }

  getDukanStore(): void {
    const supportedCountries = ['EGY', 'ARE'];
    if (!supportedCountries.includes(country.code)) {
      this.merge({
        shouldDisplayEmptyState: this.viewState.userStores.length === 0,
      });
      return;
    }

    const dukanStore = {
      name: 'STORES.DUKAN.NAME',
      icon: mediaUrl('learning/dukan.svg'),
      provider: DUKAN_PROVIDER_NAME,
      redirectUri: '',
      supportedCountries: ['EGY', 'ARE'],
    };

    const selectedStore = this.viewState.userStores[0]?.provider
      ? this.viewState.userStores[0]
      : dukanStore;

    this._getShopUseCase.execute().subscribe({
      next: (res) => {
        const userStores = [...this.viewState.userStores, dukanStore];
        this.merge({
          isUserEligibleToDukan: true,
          userStores,
          selectedStore,
          currentStoreCourse: this.getSelectedStoreCourses(selectedStore),
          dukanLinkedStore: {
            market: country.code,
            provider: DUKAN_PROVIDER_NAME,
            storeId: this._sanitizer.bypassSecurityTrustUrl(res.url) as string,
            state: res.state,
          },
          shouldDisplayEmptyState: userStores.length === 0,
        });
      },
      error: (err) => {
        const isUserEligibleToDukan = err.status === 404;
        const userStores = [...this.viewState.userStores];

        if (isUserEligibleToDukan) {
          userStores.push(dukanStore);
        }

        this.merge({
          isUserEligibleToDukan,
          userStores,
          selectedStore: this.viewState.userStores[0]?.provider
            ? this.viewState.userStores[0]
            : dukanStore,
        });
      },
    });
  }

  public updateDukanStoreData(data: any): void {
    this.merge({
      dukanLinkedStore: {
        market: country.code,
        provider: DUKAN_PROVIDER_NAME,
        storeId: this._sanitizer.bypassSecurityTrustUrl(data.url) as string,
        state: data.state,
      },
    });
  }

  public storeUrl(): string {
    let url = '';

    if (
      this.viewState.selectedStore?.provider === this.viewState.currentCountryLinkedStore?.provider
    ) {
      url = this.viewState.currentCountryLinkedStore?.storeId || '';
    }

    if (url && !url.startsWith('https')) {
      url = `https://${url}`;
    }

    if (
      this.viewState.selectedStore.provider === DUKAN_PROVIDER_NAME &&
      this.viewState.dukanLinkedStore?.storeId
    ) {
      url = this.viewState.dukanLinkedStore?.storeId;
    }

    return url;
  }
}
